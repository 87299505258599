// @ts-check

export const PERMISSIONS = {
  CHANGE_VARIANT: 'allow_customers_to_change_variants',
  SUBSCRIBE_AND_SAVE_DISCOUNT: 'subscribe_and_save_discount_enabled',
  FREQUENCY_RULE: 'allow_customer_adjust_frequency_rule',
  CAN_USE_PLANS: 'can_use_plans',
  ENABLE_AFFINITY_DYNAMIC_BUNDLE_FREQUENCY_FILTER: 'enable_affinity_dynamic_bundle_frequency_filter',
};

export const getPermission = (permission, storePermissions, defaultValue = null) => {
  if (!Object.keys(storePermissions).length) return defaultValue;

  return storePermissions[permission];
};

/**
 * Check if the user can perform an action based on the store configuration and permissions.
 * @param {Array<String>} permissions - Array of permissions to check
 * @param {Object} storePermissions - Store permissions object
 * @param {*} defaultValue - Default value to return if the store permissions are not available
 * @returns {Boolean} - True if the user has all the permissions, false otherwise
 */
export const hasPermissions = (permissions, storePermissions, defaultValue = false) => {
  if (!Object.keys(storePermissions).length) return defaultValue;

  return permissions.every((permission) => Boolean(storePermissions[permission]));
};

export default {
  install: (app) => {
    app.config.globalProperties.$hasPermissions = (permissions, defaultValue = true) => {
      const storePermissions = app.config.globalProperties.$store.getters.getStorePermissions;
      return hasPermissions(permissions, storePermissions, defaultValue);
    };
  },
};
